import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faGauge,
  faHouse,
  faMoneyBill,
  faPaintRoller,
  faPlus,
  faReceipt,
  faRectangleAd,
  faRightFromBracket,
  faRss,
  faShirt,
  faShop,
  faUser,
  faWallet
} from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"

library.add(faWallet);
library.add(faHouse);
library.add(faFacebook)
library.add(faInstagram)
library.add(faDiscord)
library.add(faTwitter)
library.add(faYoutube)
library.add(faRss)
library.add(faPlus)
library.add(faShop)
library.add(faShirt)
library.add(faGauge)
library.add(faMoneyBill)
library.add(faRectangleAd)
library.add(faReceipt)
library.add(faCog)
library.add(faPaintRoller)
library.add(faUser)
library.add(faRightFromBracket)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
