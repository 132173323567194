import { Button } from '@mui/joy'
import React from 'react'

const BandButton = (props: { text: string; onClick?: () => void }) => {
    return (
        <Button
            onClick={props.onClick}
            sx={{
                background: 'linear-gradient(81.02deg, rgb(250, 85, 96) -23.47%, rgb(177, 75, 244) 45.52%, rgb(77, 145, 255) 114.8%)',
                borderRadius: '12px',
                border: 'calc(0.063rem) solid rgb(36, 44, 62)',
                transition: 'none',
                color: 'white',
                borderWidth: '0px',
                padding: '5px 2px',
                minWidth: '75px',
                fontFamily: 'DM Sans',
                '&:hover': {
                    background: 'linear-gradient(81.02deg, rgba(250, 85, 96, 0.5) -23.47%, rgba(177, 75, 244, 0.5) 45.52%, rgba(77, 145, 255, 0.5) 114.8%)',
                    color: 'white', // Change text color on hover if needed
                },
            }}
        >
            {props.text}
        </Button>
    );
};

export default BandButton;