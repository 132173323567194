import React from 'react';
import logo from './logo.svg';
import './App.css';
import BandButton from './components/buttons/BandButton';
import Sidebar from './sections/Sidebar';
import Main from './components/main/main';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import routes from './route-config';
import Home from './pages/home/Home';
import CreateMerch from './pages/createmerch/CreateMerch';

function App() {
  return (
    <>
      <Router>
        <div className='app'>
          <div className='sidebar' style={{ height: '100vh', overflowY: 'scroll', backgroundImage: 'linear-gradient(90deg, rgb(6, 8, 13) 0%, rgb(13, 18, 28) 100%)' }}>
            <Sidebar />
          </div>
          <div className='main'>
            <Routes>
              <Route element={<ProtectedRoute />}>
                {routes.map(route =>
                  <Route
                    path={route.path}
                    key={route.path}
                    element={
                      route.component
                    }>
                  </Route>
                )}
              </Route>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
