import { icon, text } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/joy'
import React from 'react'

const MenuItem = (props: { icon: any, text: any, isActive: any, onClick: any }) => {
    return (
        <Box
            className="menu-item"
            sx={{
                marginLeft: 2,
                marginTop: '2px',
                marginBottom: '2px',
                display: 'flex',
                alignItems: 'center',
                color: props.isActive ? 'white' : 'rgba(255, 255, 255, 0.48)',
                backgroundColor: props.isActive ? 'grey' : 'transparent',
                '&:hover': {
                    color: 'white', // Change font color on hover
                    backgroundColor: 'grey', // Change background color on hover
                    '& svg': {
                        color: 'white', // Change icon color on hover
                    },
                },
            }}
            onClick={props.onClick}
        >
            <Box sx={{ minWidth: '20px' }}>
                <FontAwesomeIcon color='inherit' icon={props.icon} size='1x' />
            </Box>
            <Typography sx={{ marginLeft: 1, p: 0.5, color: 'inherit', fontFamily: 'DM Sans' }} level="body-sm">{props.text}</Typography>
        </Box>
    )
}

export default MenuItem