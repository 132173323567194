import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Divider, Typography } from '@mui/joy'
import React, { useState } from 'react'
import MenuItem from './MenuItem';
import { useNavigate } from 'react-router-dom'

const SideBarMenu = () => {

    const [activeItem, setActiveItem] = useState(null);

    const navigate = useNavigate()

    const handleItemClick = (item: any) => {
        setActiveItem(item === activeItem ? null : item);
        handleNavigate(item);
    };

    const handleNavigate = (route: string) => {
        navigate(`/${route}`)
    }

    const handleSocialNavigate = (route: string) => {

    }

    const handleLogout = () => {

    }

    return (
        <Box component="section" sx={{ p: 1, textAlign: 'left', alignItems: 'center' }}>
            <Box sx={{ p: 1 }}>
                <Divider orientation="horizontal" />
            </Box>
            <Box>
                <MenuItem icon='house' text="Home" isActive={activeItem === 'home'} onClick={() => handleItemClick('home')} />
                <MenuItem icon='user' text="Profile" isActive={activeItem === 'profile'} onClick={() => handleItemClick('profile')} />
                <MenuItem icon='rss' text="Feed" isActive={activeItem === 'feed'} onClick={() => handleItemClick('feed')} />
            </Box>
            <Box sx={{ marginLeft: 1, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ p: 1, color: 'white', fontFamily: 'DM Sans' }} level="h4">Merch Tools</Typography>
            </Box>
            <Box>
                <MenuItem icon='plus' text="Create Merch" isActive={activeItem === 'createmerch'} onClick={() => handleItemClick('createmerch')} />
                <MenuItem icon='shop' text="Your Merch" isActive={activeItem === 'yourmerch'} onClick={() => handleItemClick('yourmerch')} />
                <MenuItem icon='shirt' text="Available Range" isActive={activeItem === 'availablerange'} onClick={() => handleItemClick('availablerange')} />
                <MenuItem icon='paint-roller' text="Your Artwork" isActive={activeItem === 'yourartwork'} onClick={() => handleItemClick('yourartwork')} />
            </Box>
            <Box sx={{ marginLeft: 1, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ p: 1, color: 'white', fontFamily: 'DM Sans' }} level="h4">Sales & Marketing</Typography>
            </Box>
            <Box>
                <MenuItem icon='gauge' text="Sales" isActive={activeItem === 'sales'} onClick={() => handleItemClick('sales')} />
                <MenuItem icon='money-bill' text="Cash Out" isActive={activeItem === 'cashout'} onClick={() => handleItemClick('cashout')} />
                <MenuItem icon='rectangle-ad' text="Marketing" isActive={activeItem === 'marketing'} onClick={() => handleItemClick('marketing')} />
                <MenuItem icon='receipt' text="Order Promotionals" isActive={activeItem === 'promotionals'} onClick={() => handleItemClick('promotionals')} />
            </Box>
            <Box sx={{ p: 2 }}>
                <Divider orientation="horizontal" />
            </Box>
            <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{
                        p: 0.5,
                        color: 'rgba(255, 255, 255, 0.48)',
                        fontFamily: 'DM Sans',
                        '&:hover': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                    }}
                    level="body-sm"
                    onClick={() => handleNavigate("whatsnew")}
                >
                    What's New
                </Typography>
            </Box>
            <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{
                        p: 0.5,
                        color: 'rgba(255, 255, 255, 0.48)',
                        fontFamily: 'DM Sans',
                        '&:hover': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                    }}
                    level="body-sm"
                    onClick={() => handleNavigate("plans")}
                >
                    Premium Plans
                </Typography>
            </Box>
            <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{
                        p: 0.5,
                        color: 'rgba(255, 255, 255, 0.48)',
                        fontFamily: 'DM Sans',
                        '&:hover': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                    }}
                    level="body-sm"
                    onClick={() => handleNavigate("settings")}
                >
                    Settings
                </Typography>
            </Box>
            <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{
                        p: 0.5,
                        color: 'rgba(255, 255, 255, 0.48)',
                        fontFamily: 'DM Sans',
                        '&:hover': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                    }}
                    level="body-sm"
                    onClick={() => handleNavigate("help")}
                >
                    FAQ & Help
                </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
                <Divider orientation="horizontal" />
            </Box>
            <MenuItem icon='right-from-bracket' text="Logout" isActive={activeItem === 'logout'} onClick={() => handleLogout()} />
            <Box sx={{ marginLeft: 2, paddingTop: 2, display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Avatar size="lg">GF</Avatar>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Typography
                        sx={{
                            p: 0.5,
                            color: 'rgba(255, 255, 255, 0.48)',
                            fontFamily: 'DM Sans',
                            '&:hover': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        }}
                        level="body-sm"
                        onClick={() => handleNavigate("profile")}
                    >
                        Gerbfather
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ paddingTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <Box sx={{ p: 1 }}>
                    <Typography
                        sx={{
                            p: 0.5,
                            color: 'rgba(255, 255, 255, 0.48)',
                            fontFamily: 'DM Sans',
                            '&:hover': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        }}
                        level="body-sm"
                        onClick={() => handleNavigate("whatsnew")}
                    >
                        Terms
                    </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Typography
                        sx={{
                            p: 0.5,
                            color: 'rgba(255, 255, 255, 0.48)',
                            fontFamily: 'DM Sans',
                            '&:hover': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        }}
                        level="body-sm"
                        onClick={() => handleNavigate("whatsnew")}
                    >
                        Contact
                    </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Typography
                        sx={{
                            p: 0.5,
                            color: 'rgba(255, 255, 255, 0.48)',
                            fontFamily: 'DM Sans',
                            '&:hover': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        }}
                        level="body-sm"
                        onClick={() => handleNavigate("whatsnew")}
                    >
                        Community
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ paddingTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <Box
                    sx={{
                        p: 0.5,
                        margin: 0.5,
                        '&:hover': {
                            '& svg': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        },
                    }}
                >
                    <FontAwesomeIcon
                        color='grey'
                        icon={['fab', 'facebook']}
                        size='2x'
                        onClick={() => handleSocialNavigate("facebook")}
                    />
                </Box>
                <Box
                    sx={{
                        p: 0.5,
                        margin: 0.5,
                        '&:hover': {
                            '& svg': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        },
                    }}
                >
                    <FontAwesomeIcon
                        color='grey'
                        icon={['fab', 'instagram']}
                        size='2x'
                        onClick={() => handleSocialNavigate("instagram")}
                    />
                </Box>
                <Box
                    sx={{
                        p: 0.5,
                        margin: 0.5,
                        '&:hover': {
                            '& svg': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        },
                    }}
                >
                    <FontAwesomeIcon
                        color='grey'
                        icon={['fab', 'discord']}
                        size='2x'
                        onClick={() => handleSocialNavigate("discord")}
                    />
                </Box>
                <Box
                    sx={{
                        p: 0.5,
                        margin: 0.5,
                        '&:hover': {
                            '& svg': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        },
                    }}
                >
                    <FontAwesomeIcon
                        color='grey'
                        icon={['fab', 'twitter']}
                        size='2x'
                        onClick={() => handleSocialNavigate("twitter")}
                    />
                </Box>
                <Box
                    sx={{
                        p: 0.5,
                        margin: 0.5,
                        '&:hover': {
                            '& svg': {
                                color: 'white',
                                cursor: 'pointer'
                            },
                        },
                    }}
                >
                    <FontAwesomeIcon
                        color='grey'
                        icon={['fab', 'youtube']}
                        size='2x'
                        onClick={() => handleSocialNavigate("youtube")}
                    />
                </Box>
            </Box>
        </Box >
    )
}

export default SideBarMenu