import Contact from "./pages/Contact/Contact";
import AvailableRange from "./pages/availablerange/AvailableRange";
import CashOut from "./pages/cashout/CashOut";
import Community from "./pages/community/Community";
import CreateMerch from "./pages/createmerch/CreateMerch";
import Feed from "./pages/feed/Feed";
import Help from "./pages/help/Help";
import Marketing from "./pages/marketing/Marketing";
import Plans from "./pages/plans/Plans";
import Profile from "./pages/profile/Profile";
import Promotionals from "./pages/promotionals/Promotionals";
import Sales from "./pages/sales/Sales";
import Settings from "./pages/settings/Settings";
import Terms from "./pages/terms/Terms";
import WhatsNew from "./pages/whatsnew/WhatsNew";
import YourArtwork from "./pages/yourartwork/YourArtwork";
import YourMerch from "./pages/yourmerch/YourMerch";

const routes = [
    { path: '/profile', component: <Profile /> },
    { path: '/feed', component: <Feed /> },
    { path: '/createmerch', component: <CreateMerch /> },
    { path: '/yourmerch', component: <YourMerch /> },
    { path: '/availablerange', component: <AvailableRange /> },
    { path: '/yourartwork', component: <YourArtwork /> },
    { path: '/sales', component: <Sales /> },
    { path: '/cashout', component: <CashOut /> },
    { path: '/marketing', component: <Marketing /> },
    { path: '/promotionals', component: <Promotionals /> },
    { path: '/whatsnew', component: <WhatsNew /> },
    { path: '/plans', component: <Plans /> },
    { path: '/settings', component: <Settings /> },
    { path: '/help', component: <Help /> },
    { path: '/terms', component: <Terms /> },
    { path: '/contact', component: <Contact /> },
    { path: '/community', component: <Community /> },
]

export default routes;