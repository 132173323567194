import { Box, Typography } from '@mui/joy'
import { Console } from 'console'
import React from 'react'

const Promotionals = () => {
    return (
        <Box
            sx={{
                p: 1,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                color: 'white'
            }}
        >
            <Box>
                <Typography sx={{ p: 1, color: 'white', fontFamily: 'DM Sans' }} level="h2">Promotionals</Typography>
            </Box>
        </Box>
    )
}

export default Promotionals