import { Box, Typography } from '@mui/joy'
import React from 'react'
import logo from '../assests/logo.png'
import SalesWidget from '../components/sidebar/SalesWidget'
import SideBarMenu from '../components/menu/SideBarMenu'
import { useNavigate } from 'react-router-dom'

const Sidebar = () => {

    const navigate = useNavigate()

    function handleNavigate(route: string) {
        navigate(`/${route}`)
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Box component="section" sx={{ p: 1, textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ p: 1 }}>
                        <img
                            src={logo}
                            width={100}
                            onClick={() => handleNavigate('home')}
                            style={{ cursor: 'pointer' }}
                        />
                    </Box>
                </Box>
            </div>
            <SalesWidget />
            <SideBarMenu />
        </>
    )
}

export default Sidebar