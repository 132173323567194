import { Box, Typography } from '@mui/joy'
import React from 'react'
import BandButton from '../buttons/BandButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

const SalesWidget = () => {

    const navigate = useNavigate()

    function handleNavigate(route: string) {
        navigate(`/${route}`)
    }

    return (
        <Box component="section" sx={{ p: 0, border: '', borderRadius: '5px', width: '75%', margin: 'auto', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ p: 1.5 }}>
                <Typography sx={{ color: 'white', fontFamily: 'DM Sans' }} level="body-sm">$155.43</Typography>
            </Box>
            <Box
                sx={{
                    p: 0.5,
                    margin: 0.5,
                    '&:hover': {
                        '& svg': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                    },
                }}
            >
                <FontAwesomeIcon
                    color='grey'
                    icon={'wallet'}
                    size='2x'
                    onClick={() => handleNavigate("cashout")}
                />
            </Box>
            <Box sx={{ p: 1.5 }}>
                <BandButton text='Upgrade' onClick={() => handleNavigate('plans')} />
            </Box>
        </Box>
    )
}

export default SalesWidget